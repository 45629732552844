import { NextPage, NextPageContext } from 'next';
import nookies from 'nookies';
import Script from 'next/script';

import { NextSeo } from 'next-seo';
import React, { useEffect } from 'react';
import { useApp } from 'components/app';
import { checkUser, googleLoginUser } from 'api/ssr/user';
import { LoginForm } from 'components/common/organisms';
import { ILoginResponse, IUser } from 'interface';
import { JWT } from 'api';

declare global {
  interface Window {
    HubSpotConversations: {
      widget: {
        load: () => void;
        remove(): void;
        open(): void;
      };
    };
  }
}
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { gtmGoogleCreateAccount } from '@api/gtm/gtm';
type IPageProps = {
  user: IUser;
  googleData?: ILoginResponse;
};

const LoginPage: React.FC<IPageProps> = ({ user, googleData }: IPageProps) => {
  const { setUser } = useApp();
  const router = useRouter();
  const cookies = nookies.get();

  useEffect(() => {
    if (googleData) {
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
      }

      const responseData = JWT.setJwt(googleData);
      setUser(responseData);
      nookies.destroy(null, 'redirect', { path: '/' });

      if (!cookies?.redirect?.includes('_next') && !cookies?.redirect?.includes('password-reset'))
        router.push(cookies?.redirect || '/');
      else router.push('/');
    }
  }, [googleData]);

  if (googleData) {
    const getJwtUser = JWT.getJwtUser();
    if (getJwtUser?.id !== '' && getJwtUser?.firstLogin === true) {
      gtmGoogleCreateAccount();
    }
    return (
      <>
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
        var _hsq = window._hsq = window._hsq || [];
          _hsq.push(["identify",{
          email: '${getJwtUser.email}'
        }]);
        window.hsConversationsSettings = {
          loadImmediately: false,
          identificationEmail: '${getJwtUser.email}'
        };

  `,
          }}
        />
        <div className="loader-container-gif">
          <img src="/images/logo-loader.gif" alt="this slowpoke moves" width="40%" />
        </div>
      </>
    );
  }

  return (
    <>
      <NextSeo title="Login | Blindspot" description="" />
      <LoginForm></LoginForm>
    </>
  );
};

export const getServerSideProps = async (_ctx: NextPageContext) => {
  const user = await checkUser(_ctx);

  const data = await getSession(_ctx);

  let googleData = null;
  const req = _ctx.req;

  if (data?.user) {
    console.log('data?.user', user?.exp && user?.exp < Date.now() / 1000);
    if (user?.exp && user?.exp < Date.now() / 1000) {
      return {
        props: {
          user: null,
          googleData: null,
        },
      };
    }

    let ip: any = '';
    if (req) {
      const foundIp =
        req?.headers['x-forwarded-for'] || req?.socket.remoteAddress || req?.connection.remoteAddress || 'NONE';
      if (foundIp) {
        const ips = (typeof foundIp === 'string' ? [foundIp] : foundIp) as string[];
        ip = ips[0];
      }
    }

    googleData = await googleLoginUser(
      {
        email: data.user?.email as string,
        name: data.user?.name as string,
        ip: ip,
      },
      _ctx
    );
  } else {
    nookies.destroy(_ctx, 'user', {
      path: '/',
    });
    nookies.destroy(_ctx, 'next-auth.session-token', {
      path: '/',
    });

    nookies.destroy(_ctx, 'refreshToken', {
      path: '/',
    });
    nookies.destroy(_ctx, 'jwt', {
      path: '/',
    });
  }

  return {
    props: {
      user: user,
      googleData,
    },
  };
};

export default LoginPage;
